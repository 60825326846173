import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-life-events',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, RouterLink],
  templateUrl: './life-events.component.html',
  styleUrl: './life-events.component.scss'
})
export class LifeEventsComponent {

  email: string = 'support@sprucepower.com';

  // Method to scroll to the target section
  scrollToSection(): void {
    const element = document.getElementById('Home-Sales-Refinancing');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
}
