<app-header></app-header>

<div class="home-sales-section">
  <div class="container">
    <div class="row align-items-end">
      <div class="col-sm-12 col-lg-6 ps-2 pe-2 ps-lg-4 pe-lg-5">
        <div class="home-heading mt-md-5 mt-sm-4">
          <h2>Life Events & Major Changes</h2>
        </div>
        <hr class="line" />
        <div>
          <div class="mt-1">
            If you decide to sell your home, we will help you transfer the
            solar-system obligations to the buyer, so they can immediately enjoy
            the clean power your home’s solar system generates. If you’ve
            decided to refinance your home, we will work with you and your
            lender to release the filings so you can get this accomplished.
          </div>
          <div class="mt-3">
            You can find more information on your options in the
            <a [routerLink]="['/faq']">FAQs</a>
            and on this page. However, you should also review the information in
            your solar agreement. This will have the most accurate and complete
            description of your home sale options. Contact us early in the sale
            (ideally within 30 days). We can help you understand the options and
            take the appropriate next steps.
          </div>
          <div class="refinancing-home-form mt-3">
            
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 ps-2 pe-2 ps-lg-4 pe-lg-4 pt-3">
        <div class="major-changes-image"></div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-5 pb-5">
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card">
        <div>
          <div class="text-center">
            <img src="/assets/book.png" />
          </div>
          <h2 class="mt-4">Refinancing your home</h2>
          <p class="mt-3">
            Before refinancing your home, you will need to provide us with the
            information below.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/refinance-information-request']">Go To Form</a>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card">
        <div>
          <div class="text-center">
            <img src="/assets/solar.png" />
          </div>
          <h2 class="mt-4">Selling your home</h2>
          <p class="mt-3">
            Changes to current lease due to sale of your home. Must be completed
            prior to final close of sales.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/home-sale-information/escrow-facilitated-transactions-request']">Go To Form (Escrow)</a>
        </div>
        <div class="refinancing-home-form mt-3">
          <a [routerLink]="['/life-events/form/home-sale-information/lawyer-facilitated-transactions-request']">Go To Form (Lawyer)</a>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card">
        <div>
          <div class="text-center">
            <img src="/assets/home-image2.png" />
          </div>
          <h2 class="mt-4">Lease Changes</h2>
          <p class="mt-3">
            Changes to the names on the lease can occur for several reasons
            including divorce, the passing of a lessee, or the need.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/solar-agreement-amendment-request']">Go To Form</a>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card">
        <div>
          <div class="text-center">
            <img src="/assets/noun-money-5204439-1283C6.png" />
          </div>
          <h2 class="mt-4">System Buyout</h2>
          <p class="mt-3">
            To calculate an up to date price for buying out your system, please
            fill out the form below.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/buyout-and-prepayment-request']">Go To Form</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sales-fqa-section">
  <div class="container pt-sm-2 pt-md-2 mt-lg-5 pb-5">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="general-faq-image"></div>
      </div>
      <div class="col-sm-12 col-lg-6 ps-sm-2 ps-lg-5">
        <div>
          <h2 class="general-faq-title">General FAQs</h2>
        </div>
        <div class="row pt-sm-3">
          <div class="col-12 p-0 mt-4 mt-sm-4 mt-md-4 mt-lg-5">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What happens at the end of the lease term?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    When the lease reaches its scheduled end of the lease term,
                    you may extend the lease term or request the removal of the
                    system. If you do not extend the lease term or request
                    removal of the system, the lease automatically renews on a
                    month-to-month basis. Please review your lease contract for
                    more information. If you have questions or concerns, please
                    email
                    <a href="mailto:homesale@sprucePower.com">homesale&#64;sprucePower.com</a>
                     or call 
                     <a href="tel:+18003211770">1-800-321-1770</a>
                      opt.
                    1.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What happens in the event of death?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    In the event of death, the lease will automatically be
                    transferred to the spouse or estate of the deceased. To
                    change ownership to the estate or a trust, please visit
                    www.Spruce Power.com/life and click “Submit Online” under
                    the Lease Changes Section to submit your request online.
                    Please remember to attach all of the applicable documents so
                    we can process your request without unnecessary delays.
                    Alternatively, you can call 1-800-321-1770 opt. 1 and speak
                    to one of our customer service representatives to initiate
                    the process, or email the completed Life Event form, along
                    with a copy of the death certificate and the assumptor’s
                    state-issued ID to 
                    <a href="mailto:adt@sprucePower.com">adt&#64;sprucePower.com</a>
                    .
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How can I change the name of the Lessee (or assign it to my
                    relative) on the Solar Lease Contract?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    To update the name of the lessee, please visit www.Spruce
                    Power.com/life and click “Submit Online” under the Lease
                    Changes Section to submit your request online.
                    Alternatively, please send the completed Life Event form to
                    <a href="mailto:homesale@sprucePower.com">homesale&#64;sprucePower.com</a>
                    or call 
                    <a href="tel:+18003211770">1-800-321-1770</a>
                     opt. 1 for
                    more information.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Can I buy my system if I’m not selling my home?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Yes! If you just want to buy your system, you can email
                    <a href="mailto:leasebuyout@sprucePower.com">leasebuyout&#64;sprucePower.com</a>
                    . Within a few minutes, you
                    should receive an email with a case number and can expect to
                    be contacted by a case manager in 1-2 business days. A quote
                    will be ordered and generally is available in 3-5 days of
                    submitting your case, at which point a copy will be emailed
                    to you. Once you have received the quote, you can mail in
                    your payment to address listed. Please be sure to include a
                    copy of the quote with your payment so we can ensure the
                    payment is applied correctly.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Does a UCC filing mean there's a lien on my property
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p>
                      Your Solar Provider may have filed a Uniform Commercial
                      Code Financing Statement on the solar system, aka a UCC-1
                      fixture filing, which appears on the title of your home.
                      Please note, this filing is not a lien on your home,
                      rather it is a public record that shows that the solar
                      system installed on your home belongs to the Solar
                      Provider. Some financing providers may ask to see
                      additional documentation about the UCC filing.
                    </p>
                    <p>
                      Upon your request, we are able to temporarily terminate
                      and re-file these documents to enable your refinancing to
                      proceed. We recommend contacting us within 30 days, and at
                      least 3 weeks prior, to the expected close date. To
                      process this request there is an upfront service charge.
                      Our Homeowner Support team will be happy to walk you
                      through the process and can help with any other questions
                      you may have about the UCC filing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h2 class="buyer-faq-title">Buyer – Home Sale (Assignment/Buyout)</h2>
      </div>

      <div class="col-12 p-0 mt-3 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionFlush">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-one">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="true"
                aria-controls="flush-collapseSix"
              >
                I’m purchasing a home with a prepaid lease. Why do I need a
                credit check?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-two"
              data-bs-parent="#accordionFlush"
            >
              <div class="accordion-body">
                While the lease has been paid in advance, Spruce Power utilizes
                the credit check process to also verify identity. The credit
                check is a requirement for all lease assignments regardless of
                whether the lease agreement is prepaid or monthly.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-three">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                How do I start the process for a home sale buyout?
              </button>
            </h2>
            <div
              id="flush-collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-three"
              data-bs-parent="#accordionFlush"
            >
              <div class="accordion-body">
                You’ve already completed the first step by visiting www.Spruce
                Power.com/life
                <p>
                  Above, please click “Submit Online” under the Home Sale
                  section, and complete the required information to submit your
                  request online. You should receive an email acknowledgment
                  with a case number in a few minutes and you can expect to be
                  contacted by a case manager in 1-2 business days. CHECK YOUR
                  EMAILS. Spruce Power will be reaching out to you via email, so
                  please check your spam and junk folders to ensure you don’t
                  miss an important communication. To avoid potential delays
                  with your request, please ensure the escrow or mortgage
                  officer to attach the following documents before you click
                  “Submit”: Recorded copy of the fixture filing you need to have
                  terminated. Preliminary title report
                </p>
                <p>
                  Buyout Quote – A quote will be ordered and will be provided to
                  you in 3-4 days. If you decide to proceed with the buyout,
                  please reply to the email, indicating you wish to proceed.
                </p>
                <p>
                  Solar Purchase Documents -Upon receipt of your email request
                  to proceed, Solar purchase documents will be emailed to all
                  parties and will need to be signed by the buyer, seller, and
                  escrow company. (REQUIRED)
                </p>
                <p>
                  Fixture Terminations – Upon receipt of signed documents,
                  Spruce Power will terminate the fixture filings and provides
                  copies to all parties approximately 7 days prior to the close
                  of escrow date Please Note: As required by law in California,
                  a Notice of Independent Solar Energy Producer contract (PUC)
                  was recorded with the Leased system. If the lender or title
                  company requires an extinguishment, please attach a pre-paid
                  shipping label (FedEx or UPS) when you submit your request.
                  Spruce Power will generate an extinguishment, sign, notarize,
                  and ship this information directly to the escrow company.
                </p>
                <p>
                  NOTE: To avoid delays, please provide a return shipping label
                  for the mailing of the signed and notarized extinguishment of
                  the Notice of Independent Solar Producer Contract (CA only)
                </p>
                <p>
                  Documentation – Purchase documents will be fully executed by
                  Spruce Power and provided to all parties once we have
                  confirmed receipt and clearance of the buyout payment.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-four">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseEight"
              >
                What happens if I get declined?
              </button>
            </h2>
            <div
              id="flush-collapseEight"
              class="accordion-collapse collapse"
              aria-labelledby="flush-four"
              data-bs-parent="#accordionFlush"
            >
              <div class="accordion-body">
                Spruce Power will request for specific documents needed from the
                buyer to support credibility of assuming the lease.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-five">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNine"
                aria-expanded="false"
                aria-controls="flush-collapseNine"
              >
                What if I do not want to assume the solar lease on this home?
              </button>
            </h2>
            <div
              id="flush-collapseNine"
              class="accordion-collapse collapse"
              aria-labelledby="flush-five"
              data-bs-parent="#accordionFlush"
            >
              <div class="accordion-body">
                <p>
                  You cannot cancel nor remove the equipment. If you do not wish
                  to assume the lease:
                </p>
                <p>You can purchase the system; or,</p>
                <p>
                  Spruce Power would be happy to try to assist you in learning
                  more about the lease and the solar system, including the
                  benefits of solar. Please contact us at 1-800-321-1770 opt. 1
                  with any questions you might have.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-six">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTen"
                aria-expanded="false"
                aria-controls="flush-collapseTen"
              >
                Can I do a buyout of my Solar System during a refinance?
              </button>
            </h2>
            <div
              id="flush-collapseTen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-six"
              data-bs-parent="#accordionFlush"
            >
              <div class="accordion-body">
                Your realtor can assist you in obtaining a copy of the lease
                from the seller.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h2 class="refinance-faq-title">Refinance</h2>
      </div>

      <div class="col-12 p-0 mt-3 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-Seven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEleven"
                aria-expanded="true"
                aria-controls="flush-collapseEleven"
              >
                I'm refinancing my home; how should I start the process?
              </button>
            </h2>
            <div
              id="flush-collapseEleven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-Seven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                You’ve already completed the first step by visiting
                www.sprucepower.com/life-events
                <p>
                  Please click “Submit Online” under the Refinance section, and
                  complete the required information to submit your request
                  online. You should receive an email acknowledgment with a case
                  number in a few minutes and you can expect to be contacted by
                  a case manager in 1-2 business days. Alternatively, you can
                  download the form, fill it in electronically or by hand, and
                  email it (and the required attachments) to the Spruce Asset
                  Disposition Team at 
                  <a href="mailto:adt@sprucePower.com">adt&#64;sprucePower.com</a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-eight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwelve"
                aria-expanded="false"
                aria-controls="flush-collapseTwelve"
              >
                What is the Filing Fee Acknowledgement and Letter of
                Affirmation?
              </button>
            </h2>
            <div
              id="flush-collapseTwelve"
              class="accordion-collapse collapse"
              aria-labelledby="flush-eight"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  You must sign the Filing Fee Acknowledgement Letter before
                  Spruce Power can terminate the filings. Since Spruce Power
                  must pay the county each time we have to terminate or re-file
                  the fixtures, this is your acknowledgment that if Spruce Power
                  terminates the filings at you or your lender’s request, that
                  you will be responsible for reimbursing Spruce Power for the
                  $350.00 filing Fee. Additionally, you can request that your
                  title or escrow company sign the Letter of Affirmation, which
                  is their acknowledgment that they will collect at the close of
                  Escrow, the $350.00 filing fee and remit to Spruce after you
                  close escrow. If the $300.00 filing fee is not received by
                  Spruce Power, it will be added to your monthly invoice.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-nine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThirteen"
                aria-expanded="false"
                aria-controls="flush-collapseThirteen"
              >
                Are there any fees associated with refinancing requests?
              </button>
            </h2>
            <div
              id="flush-collapseThirteen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-Nine"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Yes, there is a $150 processing fee plus recording fees if
                Spruce records the terminations.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-ten">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFourteen"
                aria-expanded="false"
                aria-controls="flush-collapseFourteen"
              >
                How does my title officer request a termination of the UCC
                filings?
              </button>
            </h2>
            <div
              id="flush-collapseFourteen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-ten"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  You’ve already completed the first step by visiting www.Spruce
                  Power.com/lifeevents
                </p>
                <p>
                  Above, please click “Submit Online” under the Home Sale
                  Transfer section, and complete the required information to
                  submit your request online. You should receive an email
                  acknowledgement with a case number in a few minutes and you
                  can expect to be contacted by a team member in 1-2 business
                  days.
                </p>
                <p>
                  CHECK YOUR EMAILS. Spruce Power will be reaching out to you
                  via email, so please check your spam and junk folders to
                  ensure you don’t miss an important communication.
                </p>
                <p>
                  Please note that in order for Spruce Power to provide copies
                  of the fixture terminations, the lessee, escrow company agent,
                  or Title Representative are required to sign a copy of the
                  Filing Fee Affirmation and upload it along with your refinance
                  request.
                </p>
                <p>
                  To avoid potential delays with your request, please ensure the
                  escrow or mortgage officer to attach the following documents
                  before you click “Submit”:
                </p>
                <p>
                  Filing Fee affirmation signed by lessee, Escrow agent or Title
                  Representative
                </p>
                <p>
                  Recorded copy of the fixture filing you need to have
                  terminated.
                </p>
                <p>Preliminary title report</p>
                <p>
                  As required by law in California, a Notice of Independent
                  Solar Energy Producer contract (PUC) was recorded with the
                  Leased system. If the lender or title company requires a
                  subordination, please attach a pre-paid shipping label to the
                  escrow office, along with the loan details, including:
                </p>
                <p>Lender Name</p>
                <p>Loan Amount</p>
                <p>Closing Date</p>
                <p>
                  Spruce Power will generate a subordination agreement with this
                  information, sign, notarize, and ship this information
                  directly to the escrow company.
                </p>
                <p>
                  Fixture Terminations – Upon receipt all requested information,
                  Spruce Power will terminate the fixtures filings and provide
                  copies of the subordination (if applicable) to all parties via
                  email. Subordination agreements are physically mailed to the
                  escrow offices using the shipping label you provide.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-eleven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsefifteen"
                aria-expanded="false"
                aria-controls="flush-collapsefifteen"
              >
                Can I do a buyout of my Solar System during a refinance?
              </button>
            </h2>
            <div
              id="flush-collapsefifteen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-eleven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                You may purchase your Spruce Power system at any point. Please
                call Spruce Power at 888-636-0336 to order an updated buyout
                quote and learn more about the benefit of owning your system.
                Our representatives can assist you with any additional questions
                or concerns you may have.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-twelve">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSixteen"
                aria-expanded="false"
                aria-controls="flush-collapseSixteen"
              >
                Will UCC filing be reinstated after refinance?
              </button>
            </h2>
            <div
              id="flush-collapseSixteen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-twelve"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Spruce Power will refile UCC 60 days after termination of the
                original filings.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThirteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeventeen"
                aria-expanded="false"
                aria-controls="flush-collapseSeventeen"
              >
                I'm selling my home and I have an existing Solar Lease Contract.
                What are my options with my lease?
              </button>
            </h2>
            <div
              id="flush-collapseSeventeen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-hthirteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                You have two options during a home sale:
                <p>
                  Assignment of Lease You can transfer the lease to the new
                  buyer, who will assume the lease as-is and continue to pay the
                  lease payments (if any) with the same terms you have now; OR
                </p>
                <p>
                  System Purchase:​ Alternatively, you can buyout your system,
                  taking ownership of the equipment and selling the system with
                  the sale of your home. This ends the Lease obligation and no
                  further monthly lease payment would be due.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-fouteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEighteen"
                aria-expanded="false"
                aria-controls="flush-collapseEighteen"
              >
                How do I start the process for a home sale assignment?
              </button>
            </h2>
            <div
              id="flush-collapseEighteen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-fourteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Above, please click “Submit Online” under the Home Sale section,
                and complete the required information to submit your request
                online. You should receive an email acknowledgement with a case
                number in a few minutes and you can expect to be contacted by a
                case manager in 1-2 business days.
                <p>
                  CHECK YOUR EMAILS. Spruce Power will be reaching out to you
                  via email, so please check your spam and junk folders to
                  ensure you don’t miss an important communication.
                </p>
                <p>
                  To avoid potential delays with your request, please ensure the
                  escrow or mortgage officer to attach the following documents
                  before you click “Submit”:
                </p>
                Recorded copy of the fixture filing you need to have terminated.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-fifteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNineteen"
                aria-expanded="false"
                aria-controls="flush-collapseNineteen"
              >
                Preliminary title report
              </button>
            </h2>
            <div
              id="flush-collapseNineteen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-fifteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Credit Check – A credit check/identity verification application
                will be sent to the buyer (from Spruce 
                <a href="mailto:support@sprucePower.com">support&#64;sprucePower.com</a>
                ) within 3-5 business days. This application is
                mandatory and must be completed by the buyer to assume the lease
                (including Pre-paid leases)

                <p>
                  Escrow Assignment Documents – After buyer’s credit approval,
                  the lease assignment and escrow instructions will be provided
                  and will need to be signed by the buyer, seller, and escrow
                  company. The lease assignment and escrow instructions will
                  include the final seller’s payment.
                </p>
                <p>
                  Fixture Terminations – Upon receipt of signed documents,
                  Spruce Power will terminate the fixture filings and provides
                  copies to all parties approximately 7 days prior to the close
                  of escrow date As required by law in California, a Notice of
                  Independent Solar Energy Producer contract (PUC) was recorded
                  with the Leased system. If the lender or title company
                  requires an extinguishment, please attach a pre-paid shipping
                  label (FedEx or UPS) when you submit your request. Spruce
                  Power will generate an extinguishment, sign, notarize, and
                  ship this information directly to the escrow company.
                </p>
                <p>
                  Documentation – Lease Transfer documents will be fully
                  executed by Spruce Power and provided after receipt of the
                  final seller payment
                </p>
                <p>Are there any fees associated with the sale of my home?</p>
                <p>
                  Yes, there is a $300 processing fee for the termination of the
                  UCC filings.
                </p>
                <p>
                  What is the time-frame for a lease assignment and/or buyout
                  process to be completed?
                </p>
                <p>Spruce Power requires a 30-day notice prior to home sale.</p>
                <p>
                  Lease Assignment – Escrow Solar Documents can be completed
                  within 7-10 business days after credit approval. Signed lease
                  assignment documents are required for release preparation and
                  final payment (if any) must be completed and submitted to
                  Spruce Power in order to complete the assumption process.
                </p>
                <p>
                  Buy Out – Escrow Purchase Instructions can be completed within
                  7-10 business days once confirmation to exercise the buyout
                  quote has been received. Signed purchase documents are
                  required for release preparation. Payment must be completed
                  and submitted to Spruce Power in order to complete the buyout
                  process.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-sixteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwenty"
                aria-expanded="false"
                aria-controls="flush-collapseTwenty"
              >
                What paperwork will Spruce Power provide in-line with my home
                sale?
              </button>
            </h2>
            <div
              id="flush-collapseTwenty"
              class="accordion-collapse collapse"
              aria-labelledby="flush-sixteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                An Escrow Package of home sale documents will be presented to
                your escrow officer or attorney. The package should be presented
                to each party (buyer and seller) at closing by the escrow
                officer for their records.

                <p>The documents are:</p>
                <p>Escrow instructions</p>
                <p>Assignment of lease</p>
                <p>Terminated UCC filings</p>
                <p>
                  Extinguishment of PUC (notice of independent solar producer) –
                  this will be sent directly to the county within 48 hours upon
                  your Escrow/Title Company’s receipt of Escrow Package. A draft
                  will be included in the Escrow Package for your reference.
                </p>
                <p>What happens if the buyer is credit declined?</p>
                <p>
                  Spruce Power will request for specific documents needed from
                  the buyer to support credibility of assuming the lease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-select-option mt-4">
  <div class="container pt-5 pb-5">
    <div class="row pt-5 pb-5 text-center justify-content-center">
      <div class="col-12">
        <div class="question-section">
          <h1 class="mb-5">Other Questions or Help?</h1>
          <div class="refinancing-contactus-button">
            <a [routerLink]="['/get-in-touch']">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
